var columns = [{
  title: '任务ID',
  dataIndex: 'id',
  key: 'id',
  // width: '10%',
  scopedSlots: {
    customRender: 'id'
  }
}, {
  title: '任务名称',
  dataIndex: 'titles',
  key: 'titles',
  // width: '14%',
  scopedSlots: {
    customRender: 'titles'
  }
}, {
  title: '任务渠道/类型',
  dataIndex: 'typename',
  key: 'typename',
  // width: '14%',
  scopedSlots: {
    customRender: 'typename'
  }
}, {
  title: '奖励数量',
  dataIndex: 'amount',
  key: 'amount',
  // width: '20%',
  scopedSlots: {
    customRender: 'amount'
  },
  ellipsis: true
}, {
  title: '奖励单位',
  dataIndex: 'brickstype',
  key: 'brickstype',
  // width: '11%',
  scopedSlots: {
    customRender: 'brickstype'
  }
}, {
  title: '运行时间',
  dataIndex: 'time',
  key: 'time',
  // width: '10%',
  scopedSlots: {
    customRender: 'time'
  }
}, {
  title: '状态',
  dataIndex: 'status',
  key: 'status',
  // width: '11%',
  scopedSlots: {
    customRender: 'status'
  }
}, {
  title: '发放统计',
  dataIndex: 'num',
  key: 'num',
  // width: '10%',
  scopedSlots: {
    customRender: 'num'
  }
}, {
  title: '操作',
  key: 'action',
  dataIndex: 'action',
  fixed: "right",
  width: 200,
  scopedSlots: {
    customRender: 'action'
  }
}];
export { columns };